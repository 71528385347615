import React, { useState, useEffect } from "react";
import JsonData from "../data/data.json";
import { About } from '../components/about'

function GamePage() {
    const [landingPageData, setLandingPageData] = useState({});
    useEffect(() => {
      setLandingPageData(JsonData);
    }, []);
  return (
    <>
      <div className="intro page-intro">
        <div className="container">
          <div className="col-md-8">
            <h1>Ternion</h1>
          </div>
        </div>
      </div>
        <div className="conteiner">
            
        <iframe id="game" title="Ternion" frameborder="0" allow="autoplay" width="100%" height="100%" allowfullscreen="" seamless="" scrolling="no" src="https://asccw.playngonetwork.com/casino/ContainerLauncher?pid=2&gid=ternion&lang=en_CA&practice=1&channel=desktop&demo=2"></iframe>
        </div>
        <About data={landingPageData.About} />
    </>
  )
}

export default GamePage