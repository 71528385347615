import React, {useState, useEffect} from "react";
import JsonData from "../data/data.json";
import { Link } from 'react-router-dom';

export const OneGame = () => {
    const [landingPageData, setLandingPageData] = useState({});
    const [game, setGame] = useState({})
    
    useEffect(() => {
      setLandingPageData(JsonData);
      if(landingPageData.Games) {
        const theGame = landingPageData.Games.filter(game => game.title == "Ternion");
        setGame(theGame)
      }

      console.log('landingPageData.Games', landingPageData.Games)
      console.log('game', game)
    }, []);


  return (
    <div className="cta-container text-center">
      <img src="img/cta.webp" width="100%" alt="" />
      <Link className="btn-custom btn-lg page-scroll cta-button" to="/game">Play now</Link>
    </div>
  );
};
