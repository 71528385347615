import React from "react";

export const About = (props) => {
  return (
    <div id="about">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-6">
            {" "}
            <img src="img/about.jpg" className="img-responsive" alt="" />{" "}
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="about-text">
              <h2>About Tarnion game</h2>
              <p>{props.data ? props.data.paragraph : "loading..."}</p>
              <h3>Why you should try it?</h3>
              <div className="list-style">
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <ul>
                    {props.data
                      ? props.data.Why.map((d, i) => (
                          <li key={`${d}-${i}`}>{d}</li>
                        ))
                      : "loading"}
                  </ul>
                </div>
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <ul>
                    {props.data
                      ? props.data.Why2.map((d, i) => (
                          <li key={`${d}-${i}`}> {d}</li>
                        ))
                      : "loading"}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <p>The Doomsday Device has been activated, plunging the city into chaos as it faces an onslaught of monsters, meteors, and monumental natural disasters. The fate of Victory City lies in the hands of a legendary superhero trio—Ternion!</p>
            <p>Meet Marvelosa, The Incredible Wonder, and Windstorm, a team of extraordinary heroes whose unique powers must unite to stop the impending doom. The challenge is immense, but with your help, they just might save the day.</p>
            <h3>Game Features</h3>
            <p>Supercharged Symbols: Land three or more superpowered symbols to secure a win and take one step closer to victory.</p>
            <p>Random Superpowers: At any moment, the heroes’ abilities can trigger to enhance your prize, adding an electrifying twist to your spins.</p>
            <p>Free Spins Galore: Activate the Free Spins round by landing three Ternion Signal Scatters. Earn up to 35 Progressive Free Spins, amplifying your rewards with every spin.</p>
            <p>Win Multiplier: During Free Spins, a Win Multiplier increases with each spin, boosting your total win. Stacked Wilds can also combine to create epic formations, mirroring the team’s unstoppable synergy.</p>
            <p>Are you ready to join Ternion and help save Victory City? The fate of the city—and colossal wins—await you!</p>
          </div>
        </div>
      </div>
    </div>
  );
};
