import React, { useState, useEffect } from "react";

const AgeVerificationPopup = () => {
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    const isVerified = localStorage.getItem("ageVerified");
    if (!isVerified) {
      setShowPopup(true);
    }
  }, []);

  const handleVerification = (isOfAge) => {
    if (isOfAge) {
      localStorage.setItem("ageVerified", "true");
    } else {
      alert("You must be 18 or older to access this site.");
      window.location.href = "https://www.google.com"; // Redirect if underage
    }
    setShowPopup(false);
  };

  if (!showPopup) return null;

  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <h2>Are you 18 already?</h2>
        <p>You must be 18 or older to access this site. Please confirm your age.</p>
        <div className="popup-buttons">
          <button onClick={() => handleVerification(true)}>Yes</button>
          <button onClick={() => handleVerification(false)}>No</button>
        </div>
      </div>
    </div>
  );
};

export default AgeVerificationPopup;
