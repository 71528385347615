import React, { useState, useEffect } from "react";
import { Header } from "../components/header";
import { Features } from "../components/features";
import { Testimonials } from "../components/testimonials";
import { OneGame } from "../components/OneGame";
import JsonData from "../data/data.json";
import SmoothScroll from "smooth-scroll";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

function Home() {
    const [landingPageData, setLandingPageData] = useState({});
    useEffect(() => {
      setLandingPageData(JsonData);
    }, []);

  return (
  <div>
    <Header data={landingPageData.Header} />
    <Features data={landingPageData.Features} />
    <Testimonials data={landingPageData.Testimonials} />
    <OneGame />
  </div>
  )
}

export default Home;