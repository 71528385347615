import React, { useState, useEffect } from "react";
import JsonData from "../data/data.json";

function Disclaimer() {
    const [landingPageData, setLandingPageData] = useState({});
    useEffect(() => {
      setLandingPageData(JsonData);
    }, []);

  return (
    <div className="disclaimer">
        <div className="container">
            <div className="col-md-12">
                <div className="row">
                    <h2><img src='img/18.png' width="30" alt="18+ only"/> {landingPageData.Disclaimer ? landingPageData.Disclaimer.title : "Disclaimer"}:</h2>
                    <p>{landingPageData.Disclaimer ? landingPageData.Disclaimer.text : ""}</p>
            <div className="row pt-20">
                <div className="col-md-5">
                    <a href="https://www.gambleaware.org/" target='_blank' >
                        <img src="img/BeAware.png" width="100%" alt="Be gamble aware" />
                    </a>
                </div>
                <div className="col-md-1">
                    <a href="https://www.gamcare.org.uk/" target='_blank' >
                        <img src="img/gamCareColor.png" width="50" alt="GamCare" />
                    </a>
                </div>
            </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Disclaimer