import React, { useState, useEffect } from 'react';
import { Contact } from '../components/contact'
import JsonData from "../data/data.json";

function ContactPage() {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <>
      <div className="intro page-intro">
        <div className="container">
          <div className="col-md-8">
            <h1>Contact us</h1>
          </div>
        </div>
      </div>

      <Contact data={landingPageData.Contact} />
    </>

  )
}

export default ContactPage;